import React from 'react'

import Layout from '~global/Layout'
import ComponentsLibraryPageWrap from '~pageWraps/ComponentsLibraryPageWrap'

import data from '../content/components-library.yaml'

const ComponentsLibrary = () => {
  return (
    <Layout>
      <ComponentsLibraryPageWrap data={data}/>
    </Layout>
  )
}

export default ComponentsLibrary

